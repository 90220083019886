import {
    AlbumReleaseGroupsApiResponse,
    ArtistReleaseGroupsApiResponse,
    ReleaseGroupsForLabelApiResponse,
    ReleaseGroupsReleasesApiResponse,
} from '@/types/api/release_groups';
import { AlbumReleaseGroup, ArtistReleaseGroup, ReleaseGroupsForLabel } from '@/types/release_groups';

export const releaseGroupsForLabelMapper: (response: ReleaseGroupsForLabelApiResponse[]) => ReleaseGroupsForLabel[] = (
    response,
) => {
    return response.map((releaseGroup: ReleaseGroupsForLabelApiResponse) => ({
        uuid: releaseGroup.uuid,
        title: releaseGroup.title,
        description: releaseGroup.description,
        format: releaseGroup.format,
        primary_artist: releaseGroup.primary_artist,
        genres: releaseGroup.genres.map((genre) => ({
            id: genre.id,
            name: genre.name,
            is_main_genre: genre.is_main_genre,
        })),
        page: {
            uuid: releaseGroup.page.uuid,
        },
    }));
};

export const releaseGroupsMapper: (response: AlbumReleaseGroupsApiResponse) => AlbumReleaseGroup = (response) => {
    return {
        main_genre: response.main_genre,
        sub_genre: response.sub_genre,
        description: response.description,
    };
};

export const releaseGroupsReleasesMapper: (
    response: ReleaseGroupsReleasesApiResponse[],
) => ReleaseGroupsReleasesApiResponse[] = (response) => {
    // TODO: map this response during releases implementation since they are more closely related
    return response;
};

export const artistReleaseGroupsMapper: (response: ArtistReleaseGroupsApiResponse[]) => {
    singleEps: ArtistReleaseGroup[];
    albums: ArtistReleaseGroup[];
} = (response) => {
    const releaseGroups: ArtistReleaseGroup[] = response.map((releaseGroup) => {
        return {
            image: releaseGroup.image,
            media: releaseGroup.media,
            title: releaseGroup.title,
            format: releaseGroup.format,
            url: releaseGroup.url,
            uuid: releaseGroup.uuid,
            year: releaseGroup.year,
        };
    });

    const singleEps = releaseGroups.filter((releaseGroup: ArtistReleaseGroup) =>
        releaseGroup.format.includes('Single/EP'),
    );
    const albums = releaseGroups.filter((releaseGroup: ArtistReleaseGroup) => releaseGroup.format.includes('Album'));

    return { singleEps, albums };
};
