import { ReleaseApiResponse } from '@/types/api/release';
import { Release } from '@/types/release';

export const releaseMapper: (response: ReleaseApiResponse) => Release = (response) => {
    return {
        uuid: response.uuid,
        artwork_slots: response.artwork_slots,
        release_date: response.release_date,
        preorder_date: response.preorder_date,
        ean: response.ean,
        title: response.title,
        quantity_limit: response.quantity_limit,
        catalog_no: response.catalog_no,
        primary_artist: response.primary_artist,
        additional_artists: response.additional_artists,
        explicit: response.explicit,
        p_year: response.p_year,
        p_holder: response.p_holder,
        c_year: response.c_year,
        c_holder: response.c_holder,
        label: response.label,
        description: response.description,
        metadata_status: response.metadata_status,
        created_at: response.created_at,
        updated_at: response.updated_at,
        selected_options: response.selected_options,
        release_type: {
            product_type: {
                artwork_format: response.release_type.product_type.artwork_format,
                max_tracks_per_side: response.release_type.product_type.max_tracks_per_side,
                max_seconds_per_side: response.release_type.product_type.max_seconds_per_side,
                sides: response.release_type.product_type.sides,
                medium: response.release_type.product_type.medium,
                design_options: response.release_type.product_type.design_options,
                image_icon_url: response.release_type.product_type.image_icon_url,
                description: response.release_type.product_type.description,
            },
            format: response.release_type.format,
        },
        tracks: response.tracks,
        format_type_errors: response.format_type_errors,
        is_album_format: response.is_album_format,
        is_single_format: response.is_single_format,
        release_group_uuid: response.release_group_uuid,
        release_group: {
            page: {
                uuid: response.release_group?.page?.uuid || null,
                name: response.release_group?.page?.name || null,
                url: response.release_group?.page?.url || null,
                status: response.release_group?.page?.status || null,
            },
        },
        original_release_date: response.original_release_date,
        user_uuid: response.user_uuid, // Should remove
        territories: response.territories,
        territories_exclude: response.territories_exclude,
        notes: response.notes,
        tracks_status: response.tracks_status,
        track_errors: response.track_errors,
        artwork_1_url: response.artwork_1_url || null,
        artwork_2_url: response.artwork_2_url || null,
        artwork_3_url: response.artwork_3_url || null,
        artwork_4_url: response.artwork_4_url || null,
        artwork_5_url: response.artwork_5_url || null,
        artwork_1_timestamp: response.artwork_1_timestamp || null,
        artwork_2_timestamp: response.artwork_2_timestamp || null,
        artwork_3_timestamp: response.artwork_3_timestamp || null,
        artwork_4_timestamp: response.artwork_4_timestamp || null,
        artwork_5_timestamp: response.artwork_5_timestamp || null,
        artwork_1_org_filename: response.artwork_1_org_filename || null,
        artwork_2_org_filename: response.artwork_2_org_filename || null,
        artwork_3_org_filename: response.artwork_3_org_filename || null,
        artwork_4_org_filename: response.artwork_4_org_filename || null,
        artwork_5_org_filename: response.artwork_5_org_filename || null,
        artwork_1_size: response.artwork_1_size || null,
        artwork_2_size: response.artwork_2_size || null,
        artwork_3_size: response.artwork_3_size || null,
        artwork_4_size: response.artwork_4_size || null,
        artwork_5_size: response.artwork_5_size || null,
        artwork_status: response.artwork_status,
        price_category_id: response.price_category_id,
        submitted_at: response.submitted_at,
        published_at: response.published_at,
        in_process_at: response.in_process_at || null,
        artwork: response.artwork,
        operations: response.operations,
        is_vinyl: response.is_vinyl,
        price_categories: response.price_categories,
        artwork_flow: response.artwork_flow || null,
        express_artwork: response.express_artwork,
        express_flow_warnings: response.express_flow_warnings,
    };
};
