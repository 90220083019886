<template>
    <div></div>
</template>

<script setup lang="ts">
    import { computed, onMounted } from 'vue';
    import { useStore } from 'vuex';

    import {
        ARTWORK_PROCESSING_FINISHED,
        ARTWORK_PROCESSING_STARTING,
        ARTWORK_REGENERATION_STARTING,
        ARTWORK_UPLOAD_DEFAULT,
        ARTWORK_VALIDATION_FINISHED,
        ARTWORK_VALIDATION_STARTING,
        ARTWORK_VALIDATION_SUCCEEDED,
    } from '@/consts/websockets';

    import socket from '@/services/socket';
    import { notify } from '@/functions/useNotification';

    import {
        WebsocketsArtworkEventData,
        WebsocketsInstagramConnectResponse,
        WebsocketsTrackUploadResponse,
    } from '@/types/websockets';

    const store = useStore();

    const uuid = computed(() => store.state.user.uuid);

    onMounted(() => {
        connect();
    });

    const connect = () => {
        socket
            .private(`users.${uuid.value}.notifications`)
            .listen('Message', (message: string) => notify(message))
            .listen('ReleaseCloneStarted', async (e: { source: string; destination: string }) => {
                refreshReleases([e.source, e.destination]);
            })
            .listen('ReleaseCloneFinished', async (e: { source: string; destination: string }) => {
                refreshReleases([e.source, e.destination]);
            })
            .listen('ArtworkDispatched', () => {
                store.dispatch('websockets/updateArtworkProcessingState', {
                    message: ARTWORK_VALIDATION_STARTING,
                });
            })
            .listen('ArtworkValidated', (e: WebsocketsArtworkEventData) => {
                if (e.meta.silent) {
                    return;
                }

                store.dispatch('websockets/updateArtworkProcessingState', {
                    message: ARTWORK_VALIDATION_FINISHED,
                });
            })
            .listen('ArtworkValidationSucceeded', (e: WebsocketsArtworkEventData) => {
                if (e.meta.silent) {
                    return;
                }

                store.dispatch('websockets/updateArtworkProcessingState', {
                    message: ARTWORK_VALIDATION_SUCCEEDED,
                });
            })
            .listen('ArtworkProcessStarted', (e: WebsocketsArtworkEventData) => {
                if (e.meta.silent) {
                    return;
                }

                store.dispatch('websockets/updateArtworkProcessingState', {
                    message: ARTWORK_PROCESSING_STARTING,
                });
            })
            .listen('ArtworkProcessed', (e: WebsocketsArtworkEventData) => {
                if (e.meta.silent) {
                    return;
                }

                store.dispatch('websockets/updateArtworkProcessingState', {
                    message: ARTWORK_PROCESSING_FINISHED,
                });
            })
            .listen('ArtworkProcessFinished', async (e: WebsocketsArtworkEventData) => {
                if (e.meta.silent) {
                    return;
                }

                await refreshReleases([e.release_uuid]);

                setTimeout(() => {
                    store.dispatch('websockets/updateArtworkProcessingState', {
                        message: ARTWORK_UPLOAD_DEFAULT,
                    });
                }, 2000);
            })
            .listen('ArtworkProcessError', () => {
                store.dispatch('websockets/cancelArtworkUpload');
            })
            .listen('ArtworkRegenerateStarted', (e: WebsocketsArtworkEventData) => {
                store.dispatch('websockets/updateArtworkProcessingState', {
                    message: ARTWORK_REGENERATION_STARTING,
                });
            })
            .listen('ArtworkRegenerateFinished', async (e: WebsocketsArtworkEventData) => {
                await refreshReleases([e.release_uuid]);

                setTimeout(() => {
                    store.dispatch('websockets/updateArtworkProcessingState', {
                        message: ARTWORK_UPLOAD_DEFAULT,
                    });
                }, 2000);
            })
            .listen('TrackAttachedToRelease', (e: WebsocketsTrackUploadResponse) => {
                refreshReleases([e.release_uuid]);
            })
            .listen('TrackUploadComplete', (e: WebsocketsTrackUploadResponse) => {
                refreshReleases([e.release_uuid]);

                store.commit('release/TRACKS_UPLOADED');
            })
            .listen('TrackUploadFailed', (e: WebsocketsTrackUploadResponse) => {
                const formattedErrors = e.errors.map((error) => ({ file: '', message: error }));

                store.commit('uploads/reportError', { track_upload_id: e.id, errors: formattedErrors });

                store.commit('release/TRACKS_UPLOADED');
            })
            .listen('PageInstagramConnected', (e: WebsocketsInstagramConnectResponse) => {
                window.emitter.emit(`instagram-token-received-${e.page_uuid}`);
            })
            // @ts-expect-error - this is a known issue with the package
            .listenToAll((event, data) => {
                console.log(event, data);
            });
    };

    const refreshReleases = async (uuids?: string[]) => {
        await store.dispatch('releases/fetch');

        if (!uuids || uuids.length === 0) return;

        uuids.forEach(async (uuid) => {
            await store.dispatch('release/fetch', uuid);
        });

        window.emitter.emit(`reload-artwork`);
    };
</script>
